const enumSimNao = Object.freeze({
  sim: 1,
  não: 0,
});

export default enumSimNao;

export function getTextSimNao(value) {
  switch (value) {
    case enumSimNao.sim:
      return "Sim";
    case enumSimNao.não:
      return "Não";
    default:
      return null;
  }
}
