import React, { useEffect, useState, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { Row, Col, Card, CardBody, Tooltip } from "reactstrap";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAguarde, useMessageBox } from "../../../contexts/globalContext";
import api from "../../../services/api";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import formatCpf from "../../../utils/formatCpf";
import DataTableDefault from "../../../components/DataTable/DataTableDefault";
import { columnsCliente } from "./datatableColumns";
import { enumStatus } from "../../../helpers/enums";

import {
  EmailButton,
  WhatsAppButton,
  NotificationButton,
} from "../../../components/ContactButton";
import getFormattedPercent from "../../../utils/getFormattedPercent";
import enumSimNao, { getTextSimNao } from "../../../helpers/enums/enumSimNao";

const ClienteListarPage = (props) => {
  const { setAguarde } = useAguarde();
  const { setMessageBox } = useMessageBox();
  const [perfil, setPerfil] = useState("");
  const [ttop, setttop] = useState(false);
  const [clienteSelecionado] = useState("");
  const [modalExcluir, setModalExcluir] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [qtdeClientesMaior, setQtdeClientesMaior] = useState("Ativos: 0");
  const [qtdeClientesMenor, setQtdeClientesMenor] = useState("Inativos: 0");
  const [quantidadeHomens, setQuantidadeHomens] = useState("0");
  const [percentualHomens, setPercentualHomens] = useState("0 %");
  const [quantidadeMulheres, setQuantidadeMulheres] = useState("0");
  const [percentualMulheres, setPercentualMulheres] = useState("0 %");
  const [quantidadeOutros, setQuantidadeOutros] = useState("0");
  const [percentualOutros, setPercentualOutros] = useState(" 0%");
  const [quantidadeAnimais, setQuantidadeAnimais] = useState("0");
  const [quantidadeMaior, setQuantidadeMaior] = useState("Cães: 0");
  const [quantidadeMenor, setQuantidadeMenor] = useState("Gatos: 0");

  /*
  const [quantidadeAndroid, setQuantidadeAndroid] = useState('0');
  const [percentualAndroid, setPercentualAndroid] = useState('0 %');
  const [quantidadeIOS, setQuantidadeIOS] = useState('0');
  const [percentualIOS, setPercentualIOS] = useState('0 %');
  const [quantidadeSemDispositivo, setQuantidadeSemDispositivo] = useState('0');
  const [percentualSemDispositivo, setPercentualSemDispositivo] = useState(' 0%');
  

  const handleOpenWhatsApp = useCallback((fone) => {
    console.log(fone);
    window.open(
      `https://api.whatsapp.com/send/?phone=55${fone}&text&app_absent=0`,
      "_blank"
    );
  }, []);
*/

  const excluirCliente = useCallback(async () => {
    try {
      setModalExcluir(false);
      setAguarde(true);
      const response = await api.delete(`/web/cliente/${clienteSelecionado}`);

      setAguarde(false);

      if (response.data.success) {
        const auxCliente = clientes.filter(
          (cliente) => cliente.usuarioId !== clienteSelecionado
        );

        setClientes(auxCliente);
        await setMessageBox({
          show: true,
          title: "Sucesso",
          description: "Cliente excluído com sucesso.",
          success: true,
        });
      } else {
        await setMessageBox({
          show: true,
          title: "Erro",
          description: response.data.message,
          error: true,
        });
      }
    } catch (err) {
      setAguarde(false);
      await setMessageBox({
        show: true,
        title: "Erro",
        description: err.message,
        error: true,
      });
    }
  }, [setAguarde, clienteSelecionado, clientes, setMessageBox]);

  useEffect(() => {
    if (localStorage.getItem("@Cashback:authUser")) {
      const obj = JSON.parse(localStorage.getItem("@Cashback:authUser"));
      setPerfil(obj.role);
    }

    async function getCliente() {
      try {
        setAguarde(true);

        const response = await api.get("/web/cliente");
        setAguarde(false);

        if (!response.data.success) {
          throw response.data;
        }

        const {
          quantidadeHomen,
          quantidadeMulher,
          quantidadeOutro,
          quantidadeCao,
          quantidadeGato,
        } = response.data.data.reduce(
          (accumulator, cliente) => {
            if (cliente.genero.toUpperCase() === "M") {
              accumulator.quantidadeHomen += 1;
            } else {
              if (cliente.genero.toUpperCase() === "F") {
                accumulator.quantidadeMulher += 1;
              } else {
                accumulator.quantidadeOutro += 1;
              }
            }

            accumulator.quantidadeCao += cliente.quantidadeCao;
            accumulator.quantidadeGato += cliente.quantidadeGato;
            return accumulator;
          },
          {
            quantidadeHomen: 0,
            quantidadeMulher: 0,
            quantidadeOutro: 0,
            quantidadeCao: 0,
            quantidadeGato: 0,
          }
        );

        const listaCliente = response.data.data.map((cliente) => {
          return {
            ...cliente,
            cpfFormatado: formatCpf(cliente.cpf),
            quantidadeCao: (
              <div className="text-center">{cliente.quantidadeCao}</div>
            ),
            quantidadeGato: (
              <div className="text-center">{cliente.quantidadeGato}</div>
            ),
            celularFormatado: (
              <WhatsAppButton fone={cliente.celular} formated visibleText />
            ),
            genero:
              cliente.genero === "o"
                ? "Outros"
                : cliente.genero === "m"
                ? "Homem"
                : "Mulher",
            generoFormatado:
              cliente.genero === "o" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-warning font-size-11">
                    Outros
                  </span>
                </div>
              ) : cliente.genero === "m" ? (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-info font-size-11">
                    Homem
                  </span>
                </div>
              ) : (
                <div className="text-center">
                  <span className="badge badge-pill badge-soft-danger font-size-11">
                    Mulher
                  </span>
                </div>
              ),
            usuarioStatusFormatado: (
              <div className="text-center">
                <span
                  className={
                    cliente.usuarioStatus === enumStatus.ativo
                      ? "badge-pill badge-soft-success font-size-11"
                      : "badge-pill badge-soft-danger font-size-11"
                  }
                >
                  {cliente.usuarioStatusDescricao}
                </span>
              </div>
            ),
            usuarioReceberNotificacao: getTextSimNao(
              cliente.usuarioReceberNotificacao
            ),
            usuarioReceberNotificacaoFormatado: (
              <div className="text-center">
                <span
                  className={
                    cliente.usuarioReceberNotificacao === enumSimNao.sim
                      ? "badge-pill badge-soft-success font-size-11"
                      : "badge-pill badge-soft-danger font-size-11"
                  }
                >
                  {getTextSimNao(cliente.usuarioReceberNotificacao)}
                </span>
              </div>
            ),
            dataNascimento: cliente.dataNascimento
              ? format(parseISO(cliente.dataNascimento), "dd/MM/yyyy")
              : "",
            usuarioDataUltimoAcesso: cliente.usuarioDataUltimoAcesso
              ? format(
                  parseISO(cliente.usuarioDataUltimoAcesso),
                  "dd/MM/yyyy hh:mm"
                )
              : "",
            action: (
              <>
                <Link
                  to={`/cadastros/cliente/exibir?id=${cliente.clienteId}`}
                  className="mr-1 text-info"
                >
                  <i
                    className="mdi mdi-binoculars font-size-18 align-middle mr-1"
                    id="exibirTooltip"
                  ></i>
                </Link>

                <EmailButton email={cliente.usuarioEmail} />

                {cliente.usuarioReceberNotificacao === 1 ? (
                  <NotificationButton id={cliente.clienteId} />
                ) : (
                  <Link
                    to={`/`}
                    onClick={(event) => {
                      event.preventDefault();
                      setMessageBox({
                        show: true,
                        title: "Atenção",
                        description:
                          "Cliente não habilitado para receber notificações.",
                        error: true,
                      });
                    }}
                    className="mr-1 text-secondary"
                  >
                    <i
                      className="bx bx-message font-size-18 align-middle mr-1"
                      id="notificationTooltip"
                    ></i>
                  </Link>
                )}

                {perfil === "admin" && (
                  <Link
                    to={`/vendas/registro-manual?cpf=${cliente.cpf}`}
                    className="mr-1 text-success"
                  >
                    <i
                      className="bx bx-dollar font-size-18 align-middle mr-1"
                      id="exibirTooltip"
                    ></i>
                  </Link>
                )}
              </>
            ),
          };
        });
        setClientes(listaCliente);

        const qtdeClientesAtivos = response.data.data.filter(
          (x) => x.usuarioStatus === enumStatus.ativo
        ).length;

        const qtdeClientesInativos = response.data.data.filter(
          (x) => x.usuarioStatus === enumStatus.inativo
        ).length;

        const percentualAtivos = getFormattedPercent(
          qtdeClientesAtivos,
          listaCliente.length
        );

        const percentualInativos = getFormattedPercent(
          qtdeClientesInativos,
          listaCliente.length
        );

        const [qtdeClientesMaior, qtdeClientesMenor] =
          qtdeClientesAtivos >= qtdeClientesInativos
            ? [
                `Ativos: ${qtdeClientesAtivos} (${percentualAtivos})`,
                `Inativos: ${qtdeClientesInativos} (${percentualInativos})`,
              ]
            : [
                `Inativos: ${qtdeClientesInativos} (${percentualInativos})`,
                `Ativos: ${qtdeClientesAtivos} (${percentualAtivos})`,
              ];

        setQtdeClientesMaior(qtdeClientesMaior);
        setQtdeClientesMenor(qtdeClientesMenor);
        setQuantidadeHomens(quantidadeHomen);
        setQuantidadeMulheres(quantidadeMulher);
        setQuantidadeOutros(quantidadeOutro);

        const totalAnimais = quantidadeCao + quantidadeGato;
        const percentualCaes = getFormattedPercent(quantidadeCao, totalAnimais);
        const percentualGatos = getFormattedPercent(
          quantidadeGato,
          totalAnimais
        );
        const [qtdeAnimaisMaior, qtdeAnimaisMenor] =
          quantidadeCao >= quantidadeGato
            ? [
                `Cães: ${quantidadeCao} (${percentualCaes})`,
                `Gatos: ${quantidadeGato} (${percentualGatos})`,
              ]
            : [
                `Gatos: ${quantidadeGato} (${percentualGatos})`,
                `Cães: ${quantidadeCao} (${percentualCaes})`,
              ];

        setQuantidadeAnimais(totalAnimais);
        setQuantidadeMaior(qtdeAnimaisMaior);
        setQuantidadeMenor(qtdeAnimaisMenor);

        if (listaCliente.length > 0) {
          setPercentualHomens(
            getFormattedPercent(quantidadeHomen, listaCliente.length)
          );
          setPercentualMulheres(
            getFormattedPercent(quantidadeMulher, listaCliente.length)
          );
          setPercentualOutros(
            getFormattedPercent(quantidadeOutro, listaCliente.length)
          );
        } else {
          setPercentualHomens("0,00 %");
          setPercentualMulheres("0,00 %");
          setPercentualOutros("0,00 %");
        }
      } catch (err) {
        setClientes([]);
        setAguarde(false);
        await setMessageBox({
          show: true,
          title: "Erro",
          description: err.message,
          error: true,
        });
      }
    }
    getCliente();
  }, [perfil, setAguarde, setMessageBox]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Lista de Clientes" breadcrumbItem="Clientes" />

          {modalExcluir ? (
            <SweetAlert
              title="Deseja realmente excluir este cliente?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              confirmBtnText="Excluir"
              cancelBtnBsStyle="danger"
              cancelBtnText="Cancelar"
              onConfirm={excluirCliente}
              onCancel={() => {
                setModalExcluir(false);
              }}
            ></SweetAlert>
          ) : null}

          {/*
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div className="button-items">
                    <Link
                      className="btn btn-rounded btn-success waves-effect waves-light mr-1"
                      to="/cadastros/cliente/incluir"
                    >
                      <i className="bx bx-plus font-size-16 align-middle mr-1"></i>
                      Incluir
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          */}
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="mdi mdi-account-group-outline"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">
                      Clientes ({clientes.length})
                    </h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{qtdeClientesMaior}</h5>
                    <h6>{qtdeClientesMenor}</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="2">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-info text-info font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Homens</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeHomens}</h4>
                    <span className="badge badge-soft-info font-size-12">
                      {percentualHomens}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="2">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Mulheres</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeMulheres}</h4>
                    <span className="badge badge-soft-danger font-size-12">
                      {percentualMulheres}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="2">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-warning text-warning font-size-18">
                        <i className="bx bx-user"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Outros</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeOutros}</h4>
                    <span className="badge badge-soft-warning font-size-12">
                      {percentualOutros}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-success text-success font-size-18">
                        <i className="mdi mdi-dog"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">
                      <Link id="lnkQtdePets" to={`/cadastros/animal/listar`}>
                        Pets ({quantidadeAnimais})
                      </Link>
                      <Tooltip
                        placement="top"
                        isOpen={ttop}
                        target="lnkQtdePets"
                        toggle={() => {
                          setttop(!ttop);
                        }}
                      >
                        Exibir animais
                      </Tooltip>
                    </h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{quantidadeMaior}</h5>
                    <h6>{quantidadeMenor}</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/*
          <Row>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-dark text-dark font-size-18">
                        <i className="bx bxl-apple"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">iOS</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeIOS}</h4>
                    <span className="badge badge-soft-dark font-size-12"> 
                      {percentualIOS}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-success text-success font-size-18">
                        <i className="bx bxl-android"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Android</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeAndroid}</h4>
                    <span className="badge badge-soft-success font-size-12"> 
                      {percentualAndroid}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-danger text-danger font-size-18">
                        <i className="bx bx-window-close"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Sem Dispositivo</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>{quantidadeSemDispositivo}</h4>
                    <span className="badge badge-soft-danger font-size-12"> 
                      {percentualSemDispositivo}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="3">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs mr-3">
                      <span className="avatar-title rounded-circle bg-soft-warning text-warning font-size-18">
                        <i className="mdi mdi-dog"></i>
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Pets</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h5>{quantidadeMaior}</h5>
                    <h6>{quantidadeMenor}</h6>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          */}
          <Row>
            <Col className="col-12">
              <DataTableDefault dataSource={columnsCliente(clientes)} />
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ClienteListarPage;
